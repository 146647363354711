import React from 'react'
import { Link } from 'gatsby'
import { Button, Container, Grid } from '@material-ui/core'

export default function Parallax(props) {

    return (
        <div className="white-text parallax right flex flex-col mt-20" style={{ 
            backgroundImage: `url(${props.img})`,
            backgroundSize: `cover`,
            backgroundPosition: `${props.imgPosition}`
        }}>
            <Container className="h-full flex items-center justify-center py-40">
                <Grid items>
                    <Grid item className="text-center mx-auto">
                        <h3>{props.title}</h3>
                        <Link to={props.link} className="m-6 block"><Button className="ghost p-4">{props.linkTitle}</Button></Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}