import React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export default function Accordions(props) {

  return (
    <div>
      <Accordion className="jj-accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <h5 className="text-blue-700">{props.title}</h5>
        </AccordionSummary>
        <AccordionDetails>
            <p>{props.description}</p>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}