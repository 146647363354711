import React from 'react'
import { Link } from 'gatsby'
import { Container, Grid, Button } from '@material-ui/core'

export default function TwoColROw(props) {
    return (
        <Container maxWidth="lg">
            <Grid container items className={`md:py-40 two-col mh-500 flex ${props.order == true ? 'flex-row' : 'flex-row-reverse' }`} spacing={5}>
                <Grid item md={6} xs={12} className="title-block inline-flex flex-col justify-center items-center">
                    <div className="py-20 md:w-4/5 md:py-0 mx-auto text-center md:text-left">
                        {props.paragraph == true ?
                            <div dangerouslySetInnerHTML={{__html: `${props.description}`}} />
                        :
                        <>
                            <h6>{props.title}</h6>
                            <Link to={props.link}><Button className="gray-border"><span className="font-bold">{props.buttonTitle}</span></Button></Link>
                        </>
                        }
                    </div>
                </Grid>
                <Grid item md={6} xs={12} className="title-block inline-flex flex-col justify-center items-center" style={{ 
                    backgroundImage: `url(${props.bgImg})`,
                    backgroundSize: `cover`
                }}>
                </Grid>
            </Grid>
        </Container>
    )
}
