import React from 'react'
import { Container, Grid, Button}  from '@material-ui/core'

export default function Masthead(props) {
    return (
        <div className="masthead white-text masthead flex flex-col" style={{ 
            backgroundImage: `url(${props.img})`,
            backgroundSize: `cover`,
        }}>
            <Container className="h-full flex items-center justify-center py-40">
                <Grid items>
                    <Grid item className="text-center mx-auto">
                        <h1>{props.title}</h1>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
