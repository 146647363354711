import React from 'react'
import { Link } from 'gatsby'
import { Button, Grid } from '@material-ui/core'

export default function TwoCol(props) {
    return (
        <Grid container items className={`two-col flex ${props.backgroundImage == '' ? 'py-20' : 'mh-500'} ${props.order == true ? 'flex-row right' : 'flex-row-reverse left' }`}>
            <Grid item md={6} xs={12} className="inline-flex flex-col justify-center items-center py-20 md:p-0 title-block">
                <img src={props.img} />
                <h6 className={`mx-auto text-left ${props.img ? 'text-blue-700 md:w-4/12 text-center' : 'md:w-4/5 mt-20 md:mt-10 text-center'} ${props.order == true ? 'flex-row' : 'flex-row-reverse' }`}>{props.title}</h6>
            </Grid>
            { props.description ? 
                <Grid item md={6} xs={12} className="flex items-center justify-center">
                    <div dangerouslySetInnerHTML={{__html: `${props.description}`}} />
                </Grid> 
                : '' 
            }
            { props.bgImg ? 
                <Grid item md={6} xs={12} className={`flex items-center ${props.caption ? 'service-background' : 'title-block'}`}  style={{ 
                    backgroundImage: `url(${props.bgImg})`,
                    backgroundSize: `cover`
                }}>
                    <h6 className="text-center text-white relative w-7/12 mt-0 mx-auto py-40">
                        {props.caption}
                        {props.newTab ? 
                            <a href={props.newTab} className="m-6 block" target="_blank"><Button className="ghost"><span className="text-white hover:text-gray-800">View Details</span></Button></a>
                            : ''
                        }
                    </h6> 
                </Grid> 
                : ''
            }
        </Grid>
    )
}
