import React from 'react'

export default function OneCol(props) {
    return (
        <div className='py-20'>
            <div className="one-col text-center">
                <h3 className="text-blue-700 mb-4">{props.title}</h3>
                <h6 className="md:w-5/12 mx-auto">{props.description}</h6>
            </div>
        </div>
    )
}
