import React from "react"
import { Link } from 'gatsby'
import { Container, Grid, Button}  from '@material-ui/core'

import SEO from "../components/theme/seo"
import Layout from "../components/theme/layout"
import Masthead from "../components/theme/masthead"
import OneCol from '../components/one-col'
import TwoCol from "../components/two-col"
import TwoColRow from "../components/twocol-row"
import Accordion from "../components/accordion/accordion-item"
import Parallax from "../components/parallax"

import AboutBanner from "../images/about-banner.jpg"
import BusCTA from "../images/contact-cta-banner.jpg"
import Handshake from "../images/about/handshake.jpg"
import James from "../images/i-will-be-replaced.jpg"
import Building from "../images/building.jpg"
import Mini from "../images/code-conduct-new.jpg"
import TwoColROw from "../components/twocol-row"

const About = () => (
  <Layout>
    <SEO title="About Us" />
        <Masthead
            title="About Us"
            img={AboutBanner}
        />
        <Container>
            <OneCol
                intro={true}
                title="Your Neighbourhood Mechanic"
                description="At JJ Automotive Insight, we make sure our customers are involved and informed about all auto repairs."
            />

            <hr/>

            <TwoColRow
                text={true}
                paragraph={true}
                bgImg={Handshake}
                description={
                    `<h6>We explain why we recommend a particular service and show you first-hand what is happening with your vehicle. It’s our job to give you the opportunity to make informed decisions, so you can have peace of mind. </h6><br/>
                    <h6>You don’t have to go to your dealer to have your maintenance and repairs done. Simply bring your vehicle to JJ Automotive without impacting your warranty.</h6><br/>
                    <h6>We offer a 2-year, 40,000 km warranty on every part we install – period! You’ll also enjoy hourly mechanic fees that are lower than the dealerships.</h6>`
                }
            />
        </Container>

        <Parallax 
            img={BusCTA}
            imgPosition="right"
            title="Contact us for all your repairs."
            link="/contact-us"
            linkTitle="Call Us Today"
        />

        <Container>
            <OneCol
                title="Meet The Team"
                description="The friendly team at JJ Automotive Insight is highly trained & professional "
            />
        </Container>

        <Container className="twocol-container home" maxWidth="lg">    
            <TwoCol
                order={true}
                title="James has been in the auto industry for 20 years, the last 10 of which has been specializing in European vehicles. James is factory trained for VW and Audi vehicles & our team includes licensed technicians for Japanese & North American vehicles too."
                bgImg={James}          
            />
            <TwoCol
                order={false}
                title="JJ Automotive Insight is family owned and operated – we care about our community and our customers. Visit our garage in Wasaga Beach today and see for yourself just how we operate. "
                bgImg={Building}
            />
        </Container>    


        <Container className="py-20">
            <Grid item container>
                <Grid item md={12}>
                    <h3 className="text-blue-700 pb-10">Our Code of Conduct</h3>
                    <hr />                    
                </Grid>
                <Grid item md={6}>
                    <div className="accordion about-accordion mt-10">
                        <Accordion
                            title="Customer Dignity"
                            description="We promise to treat every customer and their vehicle with dignity and respect."
                        />
                        <Accordion
                            title="Customer Insight"
                            description="It is our duty to bring potential hazards regarding your vehicle to your attention and provide “Insight” on a reasonable course of action to ensure your safety."
                        />
                        <Accordion
                            title="Dependable Service"
                            description="We promise to provide honest, dependable service and repairs using only high-quality parts."
                        />
                        <Accordion
                            title="Fair Pricing"
                            description="We will match any competitor’s written estimate (exceptions apply)."
                        />
                    </div> 
                </Grid>

                <Grid item md={6} className="flex justify-center items-center">
                    <img src={Mini} />
                </Grid>

            </Grid>
        </Container>
    </Layout>
)

export default About